import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
  
  return (
    <>

      <Header />

      <main className="container">
        <h1>404</h1>
        <p>Page not found..</p>
      </main>

      <Footer />
    
    </>
  );
};

export default Home;
