import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";

import Entity from "./pages/Entity";
import Shab from "./pages/Shab";
import Certification from "./pages/Certification";
import Hra from "./pages/Hra";
import Localities from "./pages/Localities";
import Contact from "./pages/Contact";
import Add from "./pages/Add";
import Login from "./pages/Login";
import Error404 from "./pages/Error404";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/scrollToTop";
import "./style.scss"

const Layout = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Error404 />,
    children: [
      {
        path: "/",
        element: <Shab />,
      },
      {
        path: "/search/entity/welcome",
        element: <Entity />,
      },
      {
        path: "/search/shab/welcome",
        element: <Shab />,
      },
      {
        path: "/search/certification/welcome",
        element: <Certification />,
      },
      {
        path: "/hra",
        element: <Hra />,
      },
      {
        path: "/localities",
        element: <Localities />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/add",
        element: <Add />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  }
]);



function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
