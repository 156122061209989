import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

import { useEffect } from "react";

import dayjs from 'dayjs';
import axios from 'axios';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  const { order, orderBy, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {

  const template = props['template'];
  const headCells = props['headCells'];
  const updatedRows = props['rows'];

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(()=>{
    setRows(updatedRows)
  }, [updatedRows])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const removeCertification = async (certificationId) => {

    try {

      if(certificationId !== "") {

        const res = await axios.put(`/certifications/${certificationId}`, {
          id: certificationId,
        });

        setRows((current) =>
          current.filter((row) => row.id != certificationId)
        );

      }

    } catch (err) {
      console.log(err);
    }

  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
            className='companyTable'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row, index) => {

                return (
                  <>
                  { template == 'entity' &&
          
                    <TableRow
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: '35%' }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell className='companyExcerptCell'>
                        <a color="primary" class="mat-focus-indicator ob-external-link mat-raised-button mat-button-base mat-primary ng-star-inserted" aria-label=" - Opens in a new tab." aria-disabled="false" rel="noopener noreferrer" target="_blank" href={row.cantonalExcerptWeb}><span class="fa fa-external-link-alt ng-star-inserted"></span><span class="mat-button-wrapper">cantonal excerpt</span><span class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></a>
                      </TableCell>
                      <TableCell>
                        <a class="ob-external-link ng-star-inserted" aria-label=" - Opens in a new tab." rel="noopener noreferrer" target="_blank" href={`https://www.uid.admin.ch/Detail.aspx?uid_id=${row.chid}`}><span class="fa fa-external-link-alt ng-star-inserted"></span>{row.chidFormatted}</a>
                      </TableCell>
                      <TableCell>{props['legalForms'].filter((e) => e.id === row.legalFormId)[0]['title']}</TableCell>
                      <TableCell>{row.legalSeat}</TableCell>
                      <TableCell>{props['cantons'].filter((e) => e.id === row.registerOfficeId)[0]['canton']}</TableCell>
                    </TableRow>
          
                  }
                  { template === 'shab' &&
          
                    <TableRow
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: '35%' }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.legalSeat}</TableCell>
                      <TableCell>
                        {row['shabPub'][0]['mutationTypes'].length &&
                          <>
                            {row['shabPub'][0]['mutationTypes'].map((rowMutation, index) => (
                              <>
                                { props['mutationTypes'][0][rowMutation.key] !== undefined && 
                                  <span>{ props['mutationTypes'][0][rowMutation.key]['abbr'] }{ (index === row['shabPub'][0]['mutationTypes'].length-1) ? '' : ', ' }</span>
                                }
                              </>
                            ))}
                          </>
                        }
                      </TableCell>
                      <TableCell>{dayjs(row.shabDate, "YYYY-MM-DD").format("DD.MM.YYYY")}</TableCell>
                      <TableCell className='companyExcerptCell'>
                        <a color="primary" class="mat-focus-indicator ob-external-link mat-raised-button mat-button-base mat-primary ng-star-inserted" aria-label=" - Opens in a new tab." aria-disabled="false" rel="noopener noreferrer" target="_blank" href={row.cantonalExcerptWeb}><span class="fa fa-external-link-alt ng-star-inserted"></span><span class="mat-button-wrapper">cantonal excerpt</span><span class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></a>
                      </TableCell>
                      <TableCell>
                        <a class="ob-external-link" aria-label=" - Opens in a new tab." rel="noopener noreferrer" target="_blank" href={`https://www.shab.ch/shabforms/servlet/Search?EID=7&DOCID=${row.shabPub[0].shabId}`}><span class="fa fa-external-link-alt ng-star-inserted"></span> publication as a PDF </a>
                      </TableCell>
                    </TableRow>
          
                  }
                  { (template === 'certification' || template === 'add') &&
          
                    <TableRow tabIndex={-1} key={row.name}>
                      <TableCell component="th" scope="row">{row.name}</TableCell>
                      <TableCell className='companyExcerptCell'>{row.category}</TableCell>
                      <TableCell>{row.uid}</TableCell>
                      <TableCell>{row.publicationDate}</TableCell>
                      { template == 'add' &&
                        <TableCell><button type="button" onClick={ () => removeCertification(row.id) } color="primary" className="mat-focus-indicator mat-raised-button mat-button-base mat-warn ng-star-inserted mat-tbl"><span className="mat-button-wrapper"> remove </span><span matripple className="mat-ripple mat-button-ripple" /><span className="mat-button-focus-overlay" /></button></TableCell>
                      }
                    </TableRow>
          
                  }
                  { template === 'localities' &&
          
                    <TableRow
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell component="th" scope="row" sx={{ width: '21.5%' }}>{row.name}</TableCell>
                      <TableCell className='companyExcerptCell' sx={{ width: '21.5%' }}>{props['cantons'].filter((e) => e.canton === row.cantonId)[0]['shortDesc']}</TableCell>
                      <TableCell sx={{ width: '21.5%' }}>{row.cantonId}</TableCell>
                      <TableCell>{props['cantons'].filter((e) => e.canton === row.cantonId)[0]['address1']}</TableCell>
                    </TableRow>
          
                  }
                  </>
                );

              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"results per page"}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
