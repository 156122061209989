import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

const Home = () => {

  return (
    <>

      <main className="container">
        <div className="col-block col-2">
          <div className="col">
            <h2>Contact</h2>
            <address id="address" itemProp="address" itemScope itemType="http://data-vocabulary.org/Address" className="notranslate"><span itemProp="name">Federal Office of Justice (FOJ)</span><br /><span>Federal Registry of Commerce (FRC)</span><br /><span itemProp="street-address">Bundesrain 20</span><br /><span itemProp="postal-code">3003&nbsp;</span><span itemProp="locality">Bern</span></address>
            <div className="contact notranslate"><a href="tel:+41584624197" icon="none" className="ob-external-link" aria-label=" +41 (0) 58 462 41 97 - Opens in a new tab." rel="noopener noreferrer" target="_blank"><span className="fa fa-phone" /> +41 (0) 58 462 41 97</a><br /><a href="tel:+41584624483" icon="none" className="ob-external-link" aria-label=" +41 (0) 58 462 44 83 - Opens in a new tab." rel="noopener noreferrer" target="_blank"><span className="fa fa-print" /> +41 (0) 58 462 44 83</a><br /><a href="mailto:zefix@bj.admin.ch" icon="none" className="ob-external-link" aria-label=" zefix@bj.admin.ch - Opens in a new tab." rel="noopener noreferrer" target="_blank"><span className="fa fa-envelope" /> zefix@bj.admin.ch</a></div>
          </div>
          <div className="col">
            <h2>opening hours</h2>
            <dl>
              <dt>monday to friday</dt>
              <dd><span>08:30 until 12:00</span><br /><span>14:00 until 16:30</span></dd>
            </dl>
          </div>
        </div>
        <div className="ob-alert ob-angular ob-alert-info ob-font-awesome"> 
          <span className="ob-screen-reader-only">Info</span>
          <div className="ob-icon-wrapper ob-alert-icon"><span className="fa fa-info ng-star-inserted" /></div>
          <div className="ob-alert-content"> If the cantonal excerpt is not accessible, please contact the competent cantonal commercial registry office.
          </div>
        </div>
      </main>
   
    </>
  );
};

export default Home;
