import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import axios from "axios";

import EnhancedTable from "../components/EnhancedTable";
import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Navigate, Route } from "react-router-dom";
import { AuthContext } from "../context/authContext";

// const rowDb = [
//   {
//     id: 1,
//     name: 'Synth Trade',
//     category: 'finacial service',
//     uid: 'CHE-105.814.447',
//     publicationDate: '22.02.1999'
//   }
// ];

const headCells = [
  {
    id: 'business_name',
    label: 'business name',
  },
  {
    id: 'category',
    label: 'category',
  },
  {
    id: 'uid',
    label: 'UID',
  },
  {
    id: 'publication_date',
    label: 'publication date',
  },
  {
    id: 'options',
    label: 'options',
  }
];

const Home = () => {

  const [rows, setRows] = useState([])
  const [businessName, setBusinessName] = React.useState("");
  const [category, setCategory] = React.useState('');
  const [uid, setUid] = React.useState('');
  const [publicationDate, setPublicationDate] = React.useState(dayjs().format('DD.MM.YYYY'));
  const [updateRows, setUpdateRows] = React.useState(false);

  const { currentUser, logout } = useContext(AuthContext);

  axios.interceptors.request.use(
    async (config) => {
      config.headers["authorization"] = "Bearer " + currentUser;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const res = await axios.get(`/certifications`)
        setRows(res.data)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData();
  }, [updateRows])

  if (currentUser === 'null') {
      return <Navigate to="/search/shab/welcome" replace={true} />;
  }

  const addCertification = async () => {

    try {

      if(businessName !== "" && category !== "" && uid !== "" && publicationDate !== "") {

        const res = await axios.post(`/certifications`, {
          name: businessName,
          category: category,
          uid: uid,
          publicationDate: publicationDate,
        });

        setUpdateRows(true)
        setBusinessName('')
        setCategory('')
        setUid('')

      }

    } catch (err) {
      console.log(err);
    }

  };

  return (
    <>

      <main className="container">
        <div className="centered-content">
          <div>
            <form noValidate className="searchForm ng-valid ng-dirty ng-touched ng-submitted">

              <div className="col-block col-2">
                  <div className="col no-bottom-margin ng-star-inserted">
                    <TextField
                      label="business name"
                      size="small"
                      sx={{ width: '100%', margin: '.2em 0', padding: '0 0 1.2em 0' }}
                      onChange={(e) => setBusinessName(e.target.value)}
                      value={businessName}
                    />
                  </div>
                  <div className="col no-bottom-margin ng-star-inserted">
                    <TextField
                      label="category"
                      size="small"
                      sx={{ width: '100%', margin: '.2em 0', padding: '0 0 1.2em 0' }}
                      onChange={(e) => setCategory(e.target.value)}
                      value={category}
                    />
                  </div>
                  <div className="col no-bottom-margin ng-star-inserted">
                    <TextField
                      label="uid"
                      size="small"
                      sx={{ width: '100%', margin: '.2em 0', padding: '0 0 1.2em 0' }}
                      onChange={(e) => setUid(e.target.value)}
                      value={uid}
                    />
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col no-bottom-margin ng-star-inserted">
                      <DatePicker
                        label="publication date"
                        onChange={(newDateUntil) => setPublicationDate(newDateUntil.format("DD.MM.YYYY"))}
                        sx={{ width: '100%', margin: '.2em 0', padding: '0 0 1.2em 0' }}
                        slotProps={{ textField: { size: 'small' } }}
                        format="DD.MM.YYYY"
                      />
                    </div>
                  </LocalizationProvider>
              </div>
      
              <div className="actionRow">
                <div className="advancedOptions"></div>
                <div className="buttons">
                  <button type="button" onClick={ () => addCertification() } color="primary" className="mat-focus-indicator mat-raised-button mat-button-base mat-primary cdk-focused cdk-mouse-focused"><span className="mat-button-wrapper"> add </span><span matripple className="mat-ripple mat-button-ripple" /><span className="mat-button-focus-overlay" /></button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="ng-star-inserted">
          <div className="ng-star-inserted">

            { (rows.length !== 0) &&
              <>
                <EnhancedTable template={'add'} headCells={headCells} rows={rows} />
              </>
            }
            
          </div>
        </div>
      </main>

    </>
  );
};

export default Home;
