import React, { useRef } from "react";
import { useState } from "react";
import axios from "axios";

import EnhancedTable from "../components/EnhancedTable";
import TextField from '@mui/material/TextField';

const headCells = [
  {
    id: 'business_name',
    label: 'business name',
  },
  {
    id: 'category',
    label: 'category',
  },
  {
    id: 'uid',
    label: 'UID',
  },
  {
    id: 'publication_date',
    label: 'publication date',
  }
];

const Home = () => {

  const [legalizationNumber, setLegalizationNumber] = React.useState();
  const [rows, setRows] = useState([])
  const [searching, setSearching] = useState(false);
  
  async function startSearch() {

    setRows([])
    setSearching(true)

    if(legalizationNumber !== "") {

      const res = await axios.post(`/certifications/search/?search=${legalizationNumber}`)

      setRows(res.data)
      setSearching(false)
      
    }

    // setTimeout(() => {
    //   setRows(rowDb)
    //   setSearching(false)
    // }, 2000);

  }

  return (
    <>

      <main className="container">
        <div className="centered-content">
          <div>
            <form noValidate className="searchForm ng-valid ng-dirty ng-touched ng-submitted">

              <TextField
                label="legalization number"
                size="small"
                onChange={(e) => setLegalizationNumber(e.target.value)}
                value={legalizationNumber}
                sx={{ width: '100%', margin: '.2em 0', padding: '0 0 1.2em 0' }}
              />
      
              <div className="actionRow">
                <div className="advancedOptions"></div>
                <div className="buttons">
                  <button onClick={ () => startSearch() } type="button" color="primary" className="mat-focus-indicator mat-raised-button mat-button-base mat-primary cdk-focused cdk-mouse-focused"><span className="mat-button-wrapper"> search </span><span matripple className="mat-ripple mat-button-ripple" /><span className="mat-button-focus-overlay" /></button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="ng-star-inserted">
          <div className="ng-star-inserted">

            { searching == true &&
              <>
                <div className="alerts-wrapper">
                  <div type="info" className="ob-alert ob-angular ob-alert-info ob-font-awesome ng-star-inserted">
                    <span className="ob-screen-reader-only">Info</span>
                    <div className="ob-icon-wrapper ob-alert-icon"><span className="fa fa-info ng-star-inserted" /></div>
                    <div className="ob-alert-content">
                      <div role="img" fonticon="fa-sync" className="mat-icon notranslate ob-icon spinning small-icon-center fa fa-sync mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font" data-mat-icon-name="fa-sync" />
                      searching database. One moment please. 
                    </div>
                  </div>
                </div>
              </>
            }

            { searching == false &&
              <>
                { rows.length !== 0 &&
                  <>
                    <div class="topBar ng-star-inserted">
                      <div class="col1"><span class="ng-star-inserted">Number of results: { rows.length }</span></div>
                    </div>
                  </>
                }
              </>
            }

            { (searching == true || rows.length !== 0) &&
              <>
                <EnhancedTable template={'certification'} headCells={headCells} rows={rows} />
                <div className="centered-content content-margin-top">
                  <p>The data provided electronically by the internet database Zefix is not legally valid. You can obtain a confirmed information if a business name already exists by <a href="https://www.regix.ch/">REGIX</a>.</p>
                </div>
              </>
            }
            
          </div>
        </div>
      </main>

    </>
  );
};

export default Home;
