import React, { useContext, useRef } from "react";
import { useState } from "react";
import axios from "axios";

import TextField from '@mui/material/TextField';
import { AuthContext } from '../context/authContext.js';

const Home = () => {

  const [inputs, setInputs] = useState({
    code: ""
  });

  const [err, setError] = useState(null);

  const { login } = useContext(AuthContext)

  const handleChange = e => {
    setInputs(prev=>({...prev, [e.target.name]: e.target.value}))
  };

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      await login(inputs)
    } catch (err) {
      setError(err.response.data)
    }
  };

  return (
    <>

      <main className="container">
        <div className="centered-content">
          <div>
            <form noValidate className="searchForm ng-valid ng-dirty ng-touched ng-submitted">

              

              <div className="col no-bottom-margin ng-star-inserted">
                <TextField
                  label="xxxx - xxxx - xxxx"
                  size="small"
                  sx={{ width: '100%', margin: '.2em 0', padding: '0 0 1.2em 0' }}
                  onChange={handleChange}
                  name="code"
                />
              </div>
      
              <div className="actionRow">
                <div className="advancedOptions">{ err && <p>{err}</p>}</div>
                <div className="buttons">
                  <button type="button" onClick={handleSubmit} color="primary" className="mat-focus-indicator mat-raised-button mat-button-base mat-primary cdk-focused cdk-mouse-focused"><span className="mat-button-wrapper"> login </span><span matripple className="mat-ripple mat-button-ripple" /><span className="mat-button-focus-overlay" /></button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </main>

    </>
  );
};

export default Home;
