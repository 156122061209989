import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

import EnhancedTable from "../components/EnhancedTable";

import TextField from '@mui/material/TextField';

const cantons = [
  {
      "id": 140,
      "canton": "OW",
      "address1": "Handelsregisteramt des Kantons Obwalden",
      "address2": "St. Antonistrasse 4",
      "address3": null,
      "address4": "6060 Sarnen",
      "contact": "Pascal Müller, RA Dr. iur.",
      "phone1": "+41 41 666 62 21",
      "phone2": null,
      "fax": null,
      "email": [
          "hra@ow.ch"
      ],
      "openingHours": "08:00-11:45 13:30-17:00",
      "homepage": "https://www.ow.ch/fachbereiche/1876",
      "url2": "https://ow.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://ow.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://ow.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Obwalden",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 170,
      "canton": "ZG",
      "address1": "Handelsregister- und Konkursamt des Kantons Zug",
      "address2": "Aabachstrasse 5",
      "address3": "Postfach 857",
      "address4": "6301 Zug",
      "contact": "Andreas Hess, RA lic.iur.",
      "phone1": "+41 41 728 55 60",
      "phone2": null,
      "fax": null,
      "email": [
          "contact.hra@zg.ch"
      ],
      "openingHours": "08:00-11:45 14:00-17:00 (Montag bis 18:00)",
      "homepage": "http://www.zug.ch/behoerden/volkswirtschaftsdirektion/handelsregisteramt",
      "url2": "https://zg.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://zg.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://zg.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Zug",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 160,
      "canton": "GL",
      "address1": "Handelsregister des Kantons Glarus",
      "address2": "Zwinglistrasse 6",
      "address3": null,
      "address4": "8750 Glarus",
      "contact": "Hasime Kadriu",
      "phone1": "+41 55 646 66 30/31",
      "phone2": null,
      "fax": null,
      "email": [
          "hra@gl.ch"
      ],
      "openingHours": "08:00-12:00 13:30-17:00 Donnerstag 08:00 -12:00 13:30-17:30",
      "homepage": "https://www.gl.ch/verwaltung/volkswirtschaft-und-inneres/wirtschaft-und-arbeit/handelsregister.html/1038",
      "url2": "https://gl.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://gl.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://gl.chregister.ch/cr-portal/",
      "sprachCode": "DE",
      "shortDesc": "Glarus",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 120,
      "canton": "UR",
      "address1": "Handelsregisteramt des Kantons Uri",
      "address2": "Bahnhofstrasse 1",
      "address3": null,
      "address4": "6460 Altdorf",
      "contact": "Andrea Gnos Stadler",
      "phone1": "+41 41 875 22 72",
      "phone2": null,
      "fax": null,
      "email": [
          "hra@ur.ch",
          "Andrea.Gnos@ur.ch"
      ],
      "openingHours": "14:00-17:00",
      "homepage": "https://www.ur.ch/themen/1271",
      "url2": "https://ur.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://ur.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://ur.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Uri",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 241,
      "canton": "SO",
      "address1": "Handelsregisteramt des Kantons Solothurn",
      "address2": "Wengimattstrasse 2",
      "address3": null,
      "address4": "4710 Klus-Balsthal",
      "contact": "Markus Saner",
      "phone1": "+41 62 311 90 51",
      "phone2": null,
      "fax": null,
      "email": [
          "hr@fd.so.ch",
          "Markus.Saner@fd.so.ch",
          "mirco.mueller@fd.so.ch"
      ],
      "openingHours": "08:00-11:30 13:30-17:00",
      "homepage": "https://so.ch/verwaltung/finanzdepartement/kantonales-handelsregister/",
      "url2": "https://so.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://so.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://so.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Solothurn",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 150,
      "canton": "NW",
      "address1": "Handelsregisteramt des Kantons Nidwalden",
      "address2": "Stansstaderstrasse 54",
      "address3": null,
      "address4": "6371 Stans",
      "contact": "Anton Häfliger",
      "phone1": "+41 41 618 76 90",
      "phone2": null,
      "fax": null,
      "email": [
          "handelsregister@nw.ch"
      ],
      "openingHours": "08:00-11:30 14:00-17:00",
      "homepage": "https://www.nw.ch/hregamt/316",
      "url2": "https://nw.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://nw.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://nw.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Nidwalden",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 20,
      "canton": "ZH",
      "address1": "Handelsregisteramt des Kantons Zürich",
      "address2": "Schöntalstrasse 5",
      "address3": "Postfach",
      "address4": "8022 Zürich",
      "contact": "Jacqueline Schwarz, lic. iur.",
      "phone1": "+41 43 259 74 00",
      "phone2": null,
      "fax": null,
      "email": [
          "kanzlei.hra@ji.zh.ch"
      ],
      "openingHours": "08:00-11:30 13:00-16:30",
      "homepage": "https://www.zh.ch/de/wirtschaft-arbeit/handelsregister.html",
      "url2": "https://zh.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://zh.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://zh.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Zürich",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 130,
      "canton": "SZ",
      "address1": "Handelsregisteramt des Kantons Schwyz",
      "address2": "Bahnhofstrasse 15",
      "address3": "Postfach 1185",
      "address4": "6431 Schwyz",
      "contact": "RA lic.iur.HSG O. Aeschi LL.M.",
      "phone1": "+41 41 819 16 50",
      "phone2": null,
      "fax": null,
      "email": [
          "hr.awi@sz.ch"
      ],
      "openingHours": "08:00-11:45 13:45-17:00",
      "homepage": "https://www.sz.ch/behoerden/verwaltung/volkswirtschaftsdepartement/amt-fuer-wirtschaft/handelsregister.html/8756-8758-8802-10373-10943-10947",
      "url2": "https://sz.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://sz.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://sz.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Schwyz",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 600,
      "canton": "VS",
      "address1": "Handelsregisteramt Oberwallis",
      "address2": "Bahnhofstrasse 10",
      "address3": "Postfach 388",
      "address4": "3900 Brig",
      "contact": "Caesar Jaeger, Advokat",
      "phone1": "+41 27 923 75 20",
      "phone2": null,
      "fax": null,
      "email": [
          "info@handelsregisteramt.oberwallis.ch"
      ],
      "openingHours": "08:00-12:00 13:30-18:00",
      "homepage": "https://www.vs.ch/de/web/ext-rc/",
      "url2": "https://vo.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://vo.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://vs.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Brig (Oberwallis)",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 280,
      "canton": "BL",
      "address1": "Handelsregisteramt des Kantons Basel-Landschaft",
      "address2": "Domplatz 13",
      "address3": null,
      "address4": "4144 Arlesheim",
      "contact": "Klara Turtschi, lic. iur.",
      "phone1": "+41 61 552 45 00",
      "phone2": null,
      "fax": null,
      "email": [
          "handelsregister@bl.ch"
      ],
      "openingHours": "08:00-12:00 14:00-17:00",
      "homepage": "https://www.baselland.ch/politik-und-behorden/direktionen/sicherheitsdirektion/zivilrechtsverwaltung/handelsregister",
      "url2": "https://bl.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://bl.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://bl.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Basel-Landschaft",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 310,
      "canton": "AI",
      "address1": "Handelsregisteramt des Kantons Appenzell I. Rh.",
      "address2": "Marktgasse 2",
      "address3": null,
      "address4": "9050 Appenzell",
      "contact": "Marco Seydel MLaw Rechtsanwalt",
      "phone1": "+41 71 788 96 66",
      "phone2": null,
      "fax": null,
      "email": [
          "handelsregister@ai.ch"
      ],
      "openingHours": "08:00-12:00 13:30-17:00",
      "homepage": "https://www.ai.ch/themen/wirtschaft-und-arbeit/handelsregister",
      "url2": "https://ai.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://ai.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://ai.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Appenzell I. Rh.",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 270,
      "canton": "BS",
      "address1": "Handelsregisteramt des Kantons Basel-Stadt",
      "address2": "Claramattweg 8",
      "address3": "Postfach",
      "address4": "4001 Basel",
      "contact": "lic. iur. Thomas Vogt, RA",
      "phone1": "+41 61 267 44 55",
      "phone2": null,
      "fax": null,
      "email": [
          "info.hra@jsd.bs.ch"
      ],
      "openingHours": "08:00-11:30 13:30-16:00",
      "homepage": "https://www.jsd.bs.ch/ueber-das-departement/bereiche-abteilungen/recht/handelsregisteramt.html",
      "url2": "https://bs.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://bs.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://bs.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Basel-Stadt",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 400,
      "canton": "AG",
      "address1": "Handelsregisteramt des Kantons Aargau",
      "address2": "Bahnhofplatz 3c",
      "address3": "Postfach",
      "address4": "5001 Aarau",
      "contact": "Marcel Biondi, lic. iur.",
      "phone1": "+41 62 835 14 80",
      "phone2": null,
      "fax": null,
      "email": [
          "info.hra@ag.ch"
      ],
      "openingHours": "07:30-12:00 13:30-17:00",
      "homepage": "https://www.ag.ch/de/verwaltung/dvi/wirtschaft-arbeit/handelsregister",
      "url2": "https://ag.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://ag.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://ag.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Aargau",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 621,
      "canton": "VS",
      "address1": "Registre du commerce du Bas-Valais",
      "address2": "Chemin de la Tuilerie 3a",
      "address3": "Case postale 120",
      "address4": "1890 St-Maurice",
      "contact": "Jérôme Progin",
      "phone1": "+41 24 485 22 75",
      "phone2": null,
      "fax": null,
      "email": [
          "rdc.bv@admin.vs.ch"
      ],
      "openingHours": "09:00-11:00 14:00-17:00",
      "homepage": "https://www.vs.ch/fr/web/ext-rc/",
      "url2": "https://vb.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://vb.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=fr&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://vs.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "FR",
      "shortDesc": "St-Maurice (Bas Valais)",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 626,
      "canton": "VS",
      "address1": "Registre du commerce du Valais Central",
      "address2": "Place du Midi 30",
      "address3": "Case postale 1176",
      "address4": "1951 Sion",
      "contact": "Eddy Mariéthoz, notaire",
      "phone1": "+41 27 322 92 05",
      "phone2": null,
      "fax": null,
      "email": [
          "rdc.vc@admin.vs.ch"
      ],
      "openingHours": "09:00-11:00 14:00-17:00",
      "homepage": "https://www.vs.ch/fr/web/ext-rc/",
      "url2": "https://vc.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://vc.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=fr&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://vs.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "FR",
      "shortDesc": "Sion (Valais Central)",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 670,
      "canton": "JU",
      "address1": "Registre du commerce du Canton du Jura",
      "address2": "Rue de la Justice 2",
      "address3": null,
      "address4": "2800 Delémont",
      "contact": "Frédérique Rais Rasmann",
      "phone1": "+41 32 420 59 77",
      "phone2": null,
      "fax": null,
      "email": [
          "registre.commerce@jura.ch"
      ],
      "openingHours": "09:00-11:30 14:00-17:00",
      "homepage": "http://www.jura.ch/rc",
      "url2": "https://ju.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://ju.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=fr&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://ju.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "FR",
      "shortDesc": "Jura",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 660,
      "canton": "GE",
      "address1": "Registre du commerce du Canton de Genève",
      "address2": "Rue du Puits-Saint-Pierre 4",
      "address3": "Case postale 3597",
      "address4": "1211 Genève 3",
      "contact": "Thierry Hepp, avocat",
      "phone1": "+41 22 546 88 60",
      "phone2": null,
      "fax": null,
      "email": [
          "rc@etat.ge.ch"
      ],
      "openingHours": "09:00-12:00 14:00-17:00",
      "homepage": "https://www.ge.ch/consulter-registre-du-commerce/",
      "url2": "https://ge.ch/hrcintapp/externalCompanyReport.action?companyOfsUid=#",
      "url3": null,
      "url4": "https://ge.ch/hrcintapp/externalCompanyReport.action?companyOfsUid=#",
      "url5": "",
      "url6": "",
      "url7": "https://www.ge.ch/recherche-entreprises-registre-du-commerce-geneve/",
      "sprachCode": "FR",
      "shortDesc": "Genève",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 100,
      "canton": "LU",
      "address1": "Handelsregisteramt des Kantons Luzern",
      "address2": "Bundesplatz 14",
      "address3": null,
      "address4": "6002 Luzern",
      "contact": "Reto Berthel, lic. iur.",
      "phone1": "+41 41 228 58 16",
      "phone2": null,
      "fax": null,
      "email": [
          "handelsregister@lu.ch"
      ],
      "openingHours": "08:00-11:30 14:00-15:30",
      "homepage": "http://www.handelsregister.lu.ch/",
      "url2": "https://lu.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://lu.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://lu.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Luzern",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 645,
      "canton": "NE",
      "address1": "Registre du commerce du Canton de Neuchâtel",
      "address2": "Place des Halles 8",
      "address3": "Case postale 2976",
      "address4": "2001 Neuchâtel",
      "contact": "Vincent Rivier, avocat",
      "phone1": "+41 32 889 61 14",
      "phone2": null,
      "fax": null,
      "email": [
          "Registre.Commerce@ne.ch",
          "Miriam.Betturini@ne.ch",
          "christine.Salomon@ne.ch",
          "Vincent.Rivier@ne.ch",
          "justine.daFonseca@ne.ch"
      ],
      "openingHours": "Montag bis Donnerstag 08:00-12:00 13:30-17:00, Freitag 08:00-16:30",
      "homepage": "https://www.ne.ch/autorites/DESC/NECO/rcne/Pages/accueil.aspx",
      "url2": "https://hrc.ne.ch/hrcintapp/externalCompanyReport.action?companyOfsUid=#",
      "url3": null,
      "url4": "https://hrc.ne.ch/hrcintapp/externalCompanyReport.action?companyOfsUid=#",
      "url5": "",
      "url6": "",
      "url7": "http://www.ne.ch/autorites/DEAS/NECO/rcne/Pages/Recherche-d%27entreprises.aspx",
      "sprachCode": "FR",
      "shortDesc": "Neuchâtel",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 217,
      "canton": "FR",
      "address1": "Registre du commerce du Canton de Fribourg",
      "address2": "Boulevard de Pérolles 25",
      "address3": "Case postale",
      "address4": "1701 Fribourg",
      "contact": "Nadege Bosson",
      "phone1": "+41 26 305 30 90/91",
      "phone2": null,
      "fax": null,
      "email": [
          "rcfribourg@fr.ch",
          "Nadege.bosson@fr.ch",
          "sophie.marchon-modolo@fr.ch"
      ],
      "openingHours": "08:00-11:30 14:00-16:30",
      "homepage": "https://www.fr.ch/src",
      "url2": "https://adm.appls.fr.ch/hrcmatic/extract?companyOfsUid=#",
      "url3": null,
      "url4": "https://adm.appls.fr.ch/hrcmatic/extract?companyOfsUid=#",
      "url5": "",
      "url6": "",
      "url7": "https://www.fr.ch/src/travail-et-entreprises/entreprises/rechercher-une-entreprise-au-registre-du-commerce",
      "sprachCode": "FR",
      "shortDesc": "Fribourg",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 550,
      "canton": "VD",
      "address1": "Registre du commerce du Canton de Vaud",
      "address2": "Rue Grenade 38",
      "address3": "Case postale 198",
      "address4": "1510 Moudon",
      "contact": "Yann Decnaeck",
      "phone1": "+41 21 557 81 21",
      "phone2": null,
      "fax": null,
      "email": [
          "info.rc@vd.ch",
          "rita.de-freitas@vd.ch",
          "muhammed.celik@vd.ch",
          "martine.vesy@vd.ch",
          "carine.steiner@vd.ch"
      ],
      "openingHours": "09:00-16:00",
      "homepage": "https://www.vd.ch/toutes-les-autorites/ordre-judiciaire-vaudois-ojv/office-cantonal-du-registre-du-commerce/",
      "url2": "https://prestations.vd.ch/pub/101266/extract?lang=FR&companyOfsUid=#",
      "url3": null,
      "url4": "https://prestations.vd.ch/pub/101266/extract?lang=FR&companyOfsUid=#",
      "url5": "",
      "url6": "",
      "url7": "https://prestations.vd.ch/pub/101266/",
      "sprachCode": "FR",
      "shortDesc": "Vaud",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 350,
      "canton": "GR",
      "address1": "Grundbuchinspektorat und Handelsregister (GIHA)",
      "address2": "Ringstrasse 10",
      "address3": null,
      "address4": "7001 Chur",
      "contact": "lic. iur. Arno Lombardini, RA",
      "phone1": "+41 81 257 24 85",
      "phone2": null,
      "fax": null,
      "email": [
          "info@giha.gr.ch",
          "arno.lombardini@giha.gr.ch",
          "maria.tempesta@giha.gr.ch",
          "thomas.schmid@giha.gr.ch"
      ],
      "openingHours": "08:00-12:00 14:00-17:00 (Freitag bis 16:00)",
      "homepage": "http://www.gr.ch/DE/institutionen/verwaltung/dvs/giha/ueberuns/Seiten/home.aspx",
      "url2": "https://gr.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://gr.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://gr.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Graubünden",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 36,
      "canton": "BE",
      "address1": "Handelsregisteramt des Kantons Bern",
      "address2": "Poststrasse 25",
      "address3": null,
      "address4": "3071 Ostermundigen",
      "contact": "Michel Voutat",
      "phone1": "+41 31 633 43 60",
      "phone2": null,
      "fax": null,
      "email": [
          "hrabe@be.ch",
          "michel.voutat@be.ch",
          "silvia.sieber@be.ch",
          "sandra.stoll@be.ch",
          "fabienne.knoepfel@be.ch"
      ],
      "openingHours": "08:30-12:00 13:00-16:30",
      "homepage": "https://www.hra.dij.be.ch/de/start.html",
      "url2": "https://be.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://be.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://be.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Bern",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 290,
      "canton": "SH",
      "address1": "Handelsregisteramt des Kantons Schaffhausen",
      "address2": "Mühlentalstrasse 105",
      "address3": null,
      "address4": "8200 Schaffhausen",
      "contact": "Marcel Dubois",
      "phone1": "+41 52 632 72 22",
      "phone2": null,
      "fax": null,
      "email": [
          "handelsregisteramt@ktsh.ch"
      ],
      "openingHours": "08:30-11:30 13:30-17:00",
      "homepage": "https://sh.ch/CMS/Webseite/Kanton-Schaffhausen/Beh-rde/Verwaltung/Volkswirtschaftsdepartement/Handelsregisteramt-3872-DE.html",
      "url2": "https://sh.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://sh.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://sh.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Schaffhausen",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 300,
      "canton": "AR",
      "address1": "Handelsregisteramt des Kantons Appenzell A.Rh.",
      "address2": "Obstmarkt",
      "address3": null,
      "address4": "9100 Herisau",
      "contact": "Daniel Kobler, lic. iur.",
      "phone1": "+41 71 353 61 11",
      "phone2": null,
      "fax": null,
      "email": [
          "handelsregister@ar.ch"
      ],
      "openingHours": "08:30-11:00 14:00-16:00",
      "homepage": "https://www.ar.ch/verwaltung/departement-bau-und-volkswirtschaft/amt-fuer-wirtschaft-und-arbeit/handelsregister/",
      "url2": "https://ar.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://ar.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://ar.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Appenzell A. Rh.",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 320,
      "canton": "SG",
      "address1": "Amt für Handelsregister und Notariate Kanton SG",
      "address2": "Davidstrasse 27",
      "address3": "Postfach",
      "address4": "9001 St. Gallen",
      "contact": "Clemens Meisterhans, Dr. iur.",
      "phone1": "+41 71 229 37 24",
      "phone2": null,
      "fax": null,
      "email": [
          "info.afhn@sg.ch"
      ],
      "openingHours": "08:00-11:30 14:00-17:00",
      "homepage": "https://www.sg.ch/recht/handelsregister-notariate/handelsregister.html",
      "url2": "https://sg.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://sg.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://sg.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "St. Gallen",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 440,
      "canton": "TG",
      "address1": "Handelsregisteramt des Kantons Thurgau",
      "address2": "Bahnhofplatz 65",
      "address3": null,
      "address4": "8510 Frauenfeld",
      "contact": "Jürg Weber, Notar",
      "phone1": "+41 58 345 70 70",
      "phone2": null,
      "fax": null,
      "email": [
          "sekretariat.hz@tg.ch"
      ],
      "openingHours": "08:00-11:30 13:30-16:30",
      "homepage": "https://hz.tg.ch/handelsregister.html/3164",
      "url2": "https://tg.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://tg.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://tg.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "DE",
      "shortDesc": "Thurgau",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  },
  {
      "id": 501,
      "canton": "TI",
      "address1": "Registro di commercio del Cantone Ticino",
      "address2": "Via Tognola 7",
      "address3": null,
      "address4": "6710 Biasca",
      "contact": "Andrea Porrini",
      "phone1": "+41 91 816 29 81",
      "phone2": null,
      "fax": null,
      "email": [
          "di-rc@ti.ch",
          "Andrea.Porrini@ti.ch"
      ],
      "openingHours": "09:00-11:45 14:00-16:00",
      "homepage": "https://www4.ti.ch/di/dg/rf/registro-di-commercio/il-registro-di-commercio",
      "url2": "https://ti.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=#",
      "url3": null,
      "url4": "https://ti.chregister.ch/cr-portal/auszug/zefix.xhtml?uid=#&lang=de&shabdatum=NNNNNNNN",
      "url5": "",
      "url6": "",
      "url7": "https://ti.chregister.ch/cr-portal/suche/suche.xhtml",
      "sprachCode": "IT",
      "shortDesc": "Ticino",
      "receiveAccount": null,
      "receiveType": null,
      "receiveNotification": null,
      "sendType": null,
      "sendAccount": null
  }
]

const headCells = [
  {
    id: 'locality',
    label: 'locality',
  },
  {
    id: 'political_municipality',
    label: 'political municipality',
  },
  {
    id: 'canton',
    label: 'canton',
  },
  {
    id: 'registries_of_commerce',
    label: 'registries of commerce',
  }
];

const Home = () => {

  const [rows, setRows] = useState([])
  const [activeCanton, setActiveCanton] = useState(0);

  useEffect(()=>{
    const fetchData = async () => {
      
      try {
      
        const res = await axios.get('https://www.zefix.ch/ZefixREST/api/v1/locality.json')
        
        setRows(res.data)
        
      } catch (err) {
        console.log(err);
      }

    }
    fetchData();
  }, [])

  ////

  // const [searched, setSearched] = useState("");

  // const requestSearch = (searchedVal) => {
  //   setSearched(searchedVal);
  //   const filteredRows = rows.filter((row) => {
  //     console.log(searchedVal.toString().toLowerCase());
  //     return row.name.toLowerCase().toString().includes(searchedVal.toString().toLowerCase());
  //   });
    
  //   setRows(filteredRows);
  // };

  // const cancelSearch = () => {
  //   setSearched("");
  //   requestSearch(searched);
  // };
  
  return (
    <>

      <main className="container">
        <div className="ng-star-inserted">
            <h1>Localities &amp; Municipalities</h1>
            <EnhancedTable template={'localities'} headCells={headCells} rows={rows} cantons={cantons} />
        </div>
      </main>

    </>
  );
};

export default Home;
